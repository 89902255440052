import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AuthRoute from './HOC/AuthRoute'

import Layout from './Layout';
import Landing from './Landing';
import Reserve from './Reserve';
import About from './About';
import NotFound from './NotFound';
import Order from './Order';
import Station from './Station';
import Login from './Auth/Login';
import Logout from './Auth/Logout';
import UploadMenu from './Product/UploadMenu';
import Menu from './Menu';
import Loc2 from './Loc2';

const Root = () => (
  <BrowserRouter>
      <Layout>
        <Switch>
          <AuthRoute exact path="/stations" component={Station} />
          <AuthRoute exact path='/upload_menu' component={UploadMenu} />
          <Route exact path="/" component={Landing} />
          <Route exact path="/menu" component={Menu} />
          <Route exact path="/menu2" component={Loc2} />
          {/* <Route exact path="/reserve" component={Reserve} /> */}
          <Route exact path="/about" component={About} />
          {/* <Route exact path="/order" component={Order} /> */}
          {/* <Route exact path="/order/cart" render={() => <Order viewCart={true} />} /> */}
          <Route exact path='/login' component={Login} />
          <Route exact path='/logout' component={Logout} />
          <Route exact path='/*' component={NotFound} />
        </Switch>
      </Layout>
  </BrowserRouter>
);

export default Root;
