import React, { useState } from 'react';

import "./index.scss";
import diamondsmenu from "../../images/Limitless_diamonds_2024-12-17.pdf";

const Loc2 = () => {
  window.scrollTo(0, 0);
  return (
    <div className="menu">
      <h1>Pickering Location</h1>
      <h3>2200 Brock Rd Unit A1</h3>
      <a target="_blank" href={diamondsmenu}>
        <h1>Menu</h1>
      </a>
      <h3></h3>
    </div>
  );
};

export default Loc2;
